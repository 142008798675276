<template>
  <div class="VIPclass">
    <!-- 搜索 -->
    <kind-choose-view :show-school="true" :show-kc="false" :show-zy="true" :type="null" :entrants-type="3"
      @isOk="isOk" />
    <!-- 套餐列表 -->
    <div class="contentWrap">
      <div v-for="(item, index) in exclusiveClassList" :key="index" class="contentItem" @click="goDetails(item)">
        <img :src="item.img" class="imgItem">
        <div class="item_content">
          <div class="titleItem twoEllipsis">
            <span v-if="item.isHavePrivateClass == 1" class="xbk"> <img src="~@/assets/img/typeclass/z1.png" alt="">
            </span>
            {{ item.tiitle }}
          </div>
          <div class="tips">
            <span class="fuwu">#专属服务 · 极速答疑 · 督学跟踪</span>
            <el-divider direction="vertical" />
            <span class="courseNum">{{ item.kechengNum ? item.kechengNum + '门课程' : '暂无课程' }}</span>
          </div>
          <div class="priceItem">
            <div class="symbolOrprice">
              <span class="symbol">{{ '￥' }}</span>
              <span class="price">{{ item.price }}</span>
            </div>
            <div class="fire">
              <img class="fire-icon" src="@/assets/img/homeSeventh/fire.png">
              <div>{{ item.clicks }}人气值</div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin: 0 auto;" v-if="exclusiveClassList.length == 0">
        <el-empty description="暂无数据"></el-empty>
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination class="pagination colleges-pagination" :current-page="pageNum" :page-sizes="[12, 24, 36, 48]"
      :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"
      @size-change="handleSizeChange" @current-change="handleCurrentChange" />
  </div>
</template>

<script>
import Vue from 'vue'
import KindChooseView from '../../components/kindChooseViewkvipSeventh.vue'
import { selectPageListToIndex, selectNewPageListToIndexs } from '@/api/home'

export default {
  components: {
    KindChooseView
  },
  data() {
    return {
      exclusiveClassList: [],
      pageSize: 12,
      pageNum: 1,
      total: 0,
      search: {},
      loading: null
    }
  },
  async beforeDestroy() {
    this.loading.close()
  },
  created() {
    this.selectNewPageListToIndex()
  },
  methods: {
    /* 筛选 */
    isOk(val) {
      this.pageNum = 1
      this.search = val

      this.selectNewPageListToIndex()
    },
    /* VIP专属班列表 */
    selectNewPageListToIndex() {
      this.loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      selectNewPageListToIndexs(
        this.pageSize,
        this.pageNum,
        this.search.school,
        this.search.kind,
        this.search.zyId,
        undefined,
        undefined, /* productLine 3专属线 */
        this.search.stageName,
        this.search.areaId == 1 ? undefined : this.search.areaId
      ).then((res) => {
        this.exclusiveClassList = res.rows
        this.total = res.total
        this.loading.close()
      }).catch(() => {
        this.loading.close()
      })
    },
    /* 点击进入专属班详情 */
    goDetails(item) {
      /* this.$router.push({
        path: "/VIP/VIPClassxq",
        query: {
          id: item.id,
        },
      }); */
      if (item.productLine == 3) {
        const routeUrl = this.$router.resolve({
          path: '/VIP/VIPClassxq',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else {
        const routeUrl = this.$router.resolve({
          path: '/typeclassxq',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    /* 每页条数 */
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.selectNewPageListToIndex()
    },
    /* 当前页码 */
    handleCurrentChange(val) {
      this.pageNum = val
      this.selectNewPageListToIndex()
    }
  }
}
</script>

<style lang="less" scoped>
.VIPclass {
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  overflow: hidden;

  .contentWrap {
    margin: 40px calc(50% - 600px);
    width: calc(1200px + 24px);
    height: auto;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;

    .contentItem {
      width: calc(25% - 24px);
      height: 321px;
      background-color: #ffffff;
      border-radius: 8px;
      cursor: pointer;
      margin: 0 24px 24px 0;

      .imgItem {
        width: 100%;
        height: 161px;
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
        position: relative;

        .img {
          width: 100%;
          height: 161px;
          border-radius: 8px 8px 0 0;
        }

        .imgTips {
          width: 80px;
          height: 26px;
          background: linear-gradient(134deg, #5e6c77 0%, #222733 100%);
          border-radius: 8px 0 8px 0;
          text-align: center;
          line-height: 26px;
          color: #ffbe5d;
          font-size: 14px;
          font-family: Microsoft YaHei-Bold;
          font-weight: bold;
          font-style: italic;
          position: absolute;
          top: 0;
          left: 0;
          /* -webkit-background-clip: text; */
          /* -webkit-text-fill-color: transparent; */
        }

        .oneToOne {
          border-radius: 8px 0 8px 0;
          width: 77px;
          height: 24px;
          background: url('~@/assets/img/Home/oneToOne/one-to-one-tips.png');
          position: absolute;
          top: 0;
          left: 0;
          color: #ffffff;
          line-height: 24px;
          padding-left: 9px;
        }
      }

      .item_content {
        padding: 12px 16px;

        .titleItem {
          height: 48px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }

        .tips {
          margin-top: 12px;

          .fuwu {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #b8681e;
            line-height: 12px;
          }

          .el-divider {
            margin: auto 8px;
            height: 8px;
            background: #999999;
          }

          .courseNum {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }
        }

        .priceItem {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;

          .symbolOrprice {
            font-family: DIN BOLD;
            color: #ff5e51;
            font-size: 16px;
            font-weight: bold;
            line-height: 16px;

            .symbol {
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-size: 12px;
              font-weight: 400;
              line-height: 12px;
            }
          }

          .fire {
            display: flex;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 12px;

            .fire-icon {
              width: 12px;
              height: 12px;
              flex-shrink: 0;
              margin-right: 4px;
            }
          }
        }
      }
    }
  }

  .pagination {
    margin: auto;
  }
}

.xbk {
  img {
    width: 60px;
    height: 22px;
    display: inline;
    margin-right: 8px;
    position: relative;
    top: 5px;
  }
}
</style>
